export default {
    data: () => ({
        valid: false,
        sending:false,
        success:false,
        errors:[],
        messages:[],
        fields:{}
    }),
    computed: {
        emailRules(){
            return [
                (v) => !!v || 'Merci de saisir un mail',
                (v) => !! this.checkMail(v) || 'Ce mail ne semble pas correct'
            ]
        },
        required(){
            return [
                (v) => !! this.checkString(v) || 'Merci de compléter ce champ'
            ]
        }
    },
    methods: {
        checkString(string){
            return string  && string.length > 3;
        },
        checkMail(mail){
            return this.$db.utils.validator.isEmail(mail)
        },
        submit () {
            let me=this;
            if(this.valid){
                this.sending=true;
                this.errors=[];
                this.messages=[];
                this.success=false;
                console.log("envoyer",this.form);
                this.$api.form(this.fields,
                    function(messages){
                        me.sending=false;
                        me.success=true;
                        me.messages=messages;
                        me.$layout.stat.pageView(me.$layout.currentPage.href+"/merci",me.$layout.currentPage.pageUrl.title+" - merci")

                    },
                    function(data){
                        me.sending=false;
                        me.errors=data.errors;
                        console.log("nokkk",data.errors)
                    }
                );
            }
        },
    },
}