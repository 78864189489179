<template>
  <v-container class="carousel-pages-block suggested-pages">
    <div class="h4 text-center mb-line-1">
      Derniers articles
    </div>
    <div class="carousel">
      <v-row justify="center">
        <v-col v-for="page of articles"
               :key="`col-${page.uid}`"
               cols="12" sm="6" md="4" lg="4" xl="3"
               class="slide slide-grid">
          <page-card-portrait
              :page="page"/>
        </v-col>
      </v-row>
    </div>


    <div class="h4 text-center mb-line-1">
      Les derniers travaux de nos étudiants
    </div>
    <div class="carousel">
      <v-row justify="center">
        <v-col v-for="page of projets"
               :key="`col-${page.uid}`"
               cols="12" sm="6" md="4" lg="4" xl="3"
               class="slide slide-grid">
          <page-card-portrait
              :page="page"/>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
import PageCardPortrait from "@/Models/blocks/links/carousel-pages/page-card-portrait";
export default {
  name: "suggested-pages",
  components: {PageCardPortrait},computed:{
    articles(){
      /**
       *
       * @type {PostModel[]}
       */
      let records = this.$db.getListType("post");
      records=this.$db.utils.records.sortByDateField(records,"date_published",true);
      records=records.slice(0,3);
      return records;
    },
    projets(){
      /**
       *
       * @type {ProjetModel[]}
       */
      let records = this.$db.getListType("projet");
      records=this.$db.utils.records.sortByDateField(records,"date_published",true);
      records=records.slice(0,3);
      return records;
    }
  }
}
</script>

<style scoped>
.suggested-pages{
.carousel-pages-block{
  width: 100% !important;
}
}
</style>