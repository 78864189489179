<template>
  <div class="form-footer">
    <v-row justify="center">
      <v-col md="10" lg="6" xl="5" class="text-right">

        <v-btn @click="$emit('submit')"
               v-if="!success"
               :disabled="!valid"
               :loading="sending"
               color="#fff"
               class="txt-link"
               :text="!valid"
               :class="{
               'bg-gradient-blue white--text':valid
             }"
        >{{ labelEnvoyer }}</v-btn>

        <v-alert v-if="messages.length>0"
                 color="success"
                 tile outlined
                 class="mt-line-1 text-center"
        >
          <div v-for="message of this.messages"
               :key="'success'+message.message"
               v-html="message.message"
          />
        </v-alert>
        <v-alert v-if="errors.length>0"
                 color="error"
                 tile outlined
                 class="mt-line-1 text-center">
          <div v-for="err of this.errors"
               :key="'err'+err.message"
               v-html="err.message"
          >{{err}}</div>
        </v-alert>

      </v-col>
    </v-row>

    <div v-if="success" class="mt-line-1">
      <suggested-pages/>
    </div>



  </div>
</template>

<script>

import SuggestedPages from "@/components/organisms/suggested-pages";
export default {
  name: "form-footer",
  components: {SuggestedPages},
  props:["success","valid","sending","messages","errors"],
  computed:{
    labelEnvoyer(){
      return this.valid?"ENVOYER":"Merci de Compléter..."
    },
  }
}
</script>
