<template>
  <div class="form-contact-block form-iffdec">
      <v-container>
        <v-form v-model="valid">
        <v-row justify="center" v-if="!success">
          <v-col md="10" lg="6" xl="5">
            <v-row>
              <v-col cols="12">
                <div class="txt-annotation grey--text text-right">* champs obligatoires</div>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Nom *"
                    :rules="required"
                    v-model="fields.nom"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Prénom *"
                    :rules="required"
                    v-model="fields.prenom"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    validate-on-blur
                    label="Email *"
                    :rules="emailRules"
                    type="email"
                    v-model="fields.email"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    validate-on-blur
                    label="Objet *"
                    :rules="required"
                    v-model="fields.objet"
                />
              </v-col>
              <v-col cols="12" class="mt-5">
                <v-textarea
                    validate-on-blur
                    label="Message *"
                    :rules="required"
                    v-model="fields.message"
                    auto-grow
                    outlined
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

          <form-footer
              :success="success"
              :valid="valid"
              :sending="sending"
              :messages="messages"
              :errors="errors"
              @submit="submit"
          />

        </v-form>
      </v-container>

  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import FormsMixin from "@/Models/blocks/forms/FormsMixin";
import FormFooter from "@/Models/blocks/forms/form-footer";

export default {
  name: "form-contact-block",
  components: {FormFooter},
  mixins:[BlockMixin,FormsMixin],
  data(){
    return {
      fields:{
        formType: "contact",
        email: "",
        nom: "",
        prenom: "",
        objet: "",
        message:"",
      }
    }
  }
}
</script>